import React from "react";
import "../HomePage/HomePage.css";
import "../../asset/bootstrap/css/bootstrap-grid.min.css";
import "../../asset/bootstrap/css/bootstrap-reboot.min.css";
import "../../asset/bootstrap/css/bootstrap.min.css";
import "../../asset/dropdown/css/style.css";
import "../../asset/theme/css/style.css";
import "../../asset/web/assets/mobirise-icons2/mobirise2.css";
import "../../asset/mobirise/css/mbr-additional.css";
import moneyPDF from "../../asset/files/What Is Money-14-06.pdf";
import TNTPDF from "../../asset/files/TNT-whitepaper-07-01-25.pdf";
import FileFormatePdf from "../../asset/files/Cryptographic-Hashes.pdf";
import { Link } from "react-router-dom";
import img from "../../asset/images/small-3-1052x499-fotor-2024052795615.png";
function HomePage() {
  return (
    <>
      <section
        data-bs-version="5.1"
        className="menu menu1 cid-udkc0Ay2oi"
        once="menu"
        id="menu01-d"
      >
        <nav className="navbar navbar-dropdown navbar-fixed-top navbar-expand-lg">
          <div className="container">
            <div className="navbar-brand">
              <span className="navbar-caption-wrap">
                <Link
                  className="navbar-caption text-black text-primary display-4"
                  to="#"
                >
                  TNT
                </Link>
              </span>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-bs-toggle="collapse"
              data-target="#navbarSupportedContent"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <div className="hamburger">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul
                className="navbar-nav nav-dropdown nav-right"
                data-app-modern-menu="true"
              >
                <li className="nav-item">
                  <Link
                    className="nav-link link text-black text-primary display-4"
                    to="#"
                  >
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Home
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link link text-black text-primary display-4"
                    to="#"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </section>

      <section
        // data-bs-version="5.1"
        className="header19 mt-4"
        // id="header19-19"
      >
        <div className="">
          {/* <div className="row">
            <div className="content-wrap col-12 col-md-8">
              <h1 className="mbr-section-title mbr-fonts-style mb-4 display-2">
                <br />
                <a
                  href="assets/files/GOD-5.pdf"
                  className="text-warning"
                  target="_blank"
                >
                  <br />
                </a>
                <em>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp;&nbsp;
                </em>
                <br />
                <em>
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  <a
                    href={moneyPDF}
                    className="text-warning text-primary"
                    target="_blank"
                  >
                    <strong>Money</strong>
                  </a>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                  <a href={TNTPDF} className="text-primary" target="_blank">
                    <strong>TNT</strong>
                  </a>
                </em>
              </h1>
              <p className="mbr-fonts-style mbr-text mb-4 display-7"></p>

              <p className="mbr-fonts-style mbr-description mt-4 display-7"></p>
            </div>
          </div> */}
        </div>
        <img src={img} usemap="#image-map" className="tntimgSty" />

        <map name="image-map">
          <area
            target="_blank"
            alt="tnt"
            title="TNT"
            href={TNTPDF}
            coords="532,355,68"
            shape="circle"
          />
          <area
            target="_blank"
            alt="money"
            title="Money"
            // href="www.ss.com"
            href={moneyPDF}
            coords="536,188,69"
            shape="circle"
          />
        </map>
      </section>
      <span className="d-flex justify-content-center flex-wrap mb-2 gap-5">
        <span>
          SEC :
          <Link to="https://haykov.substack.com/p/legal-notes" target="blank">
            {" "}
            &nbsp; https://haykov.substack.com/p/legal-notes
          </Link>
        </span>

        <span>
          AIB :
          <Link
            to="https://haykov.substack.com/p/aib-assumption-induced-blindness"
            target="_blank"
          >
            {" "}
            view
          </Link>
        </span>
      </span>

      <section
        data-bs-version="5.1"
        className="features035 cid-udq4wzFIZO"
        id="features035-18"
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-9 active">
              <div className="item-wrapper">
                <div className="card-box">
                  <h4 className="card-text mbr-fonts-style display-7">
                    &nbsp;
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3 active">
              <div className="mbr-section-btn">
                <Link className="btn btn-info-outline display-7" to="#">
                  Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="display-7"
        style={{
          padding: 0,
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          alignContent: "center",
          display: "flex",
          position: "relative",
          height: "4rem",
        }}
      >
        <Link
          to="#"
          style={{
            flex: "1 1",
            height: "4rem",
            position: "absolute",
            width: "100%",
            zIndex: 1,
          }}
        >
          <img
            alt=""
            style={{ height: "4rem" }}
            src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
          />
        </Link>
        <p style={{ margin: 0, textAlign: "center" }} className="display-7">
          &#8204;
        </p>
        <Link style={{ zIndex: 1 }} to="#">
          ‌ © Copyright 2024
        </Link>
      </section>
    </>
  );
}

export default HomePage;
